<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumbTabulator.items" />
    <!--EOC-->
    <!---BOC:[header]-->

    <div v-if="this.rolePermission[`${model.permissionKeys.read}`].isAllowed">

    <BreadHeader :title="modelName">
      <!-- <v-btn
        :disabled="!model.delete || !this.rolePermission[`${model.permissionKeys.delete}`].isAllowed || childModelKey === 'ssoTeamToInternalUser' && !isTeamAdmin"
        text
        class="mx-1"
        color="error"
        :to="{
          name: 'PageServiceModelBreadTabulatorDelete',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Delete</v-btn
      > --><!-- 
      <v-btn
        :disabled="!model.edit || !this.rolePermission[`${model.permissionKeys.edit}`].isAllowed"
        text
        color="primary"
        :to="{
          name: 'PageServiceModelBreadTabulatorEdit',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Edit</v-btn> -->

        <v-btn @click="goToBack">Back</v-btn>
    </BreadHeader>
    <!--EOC-->
    <!---BOC:[error]-->
    <AError :api="api" />
    <!--EOC-->
    <!---BOC:[body]-->
    <v-card>
      <ATab :tab="tab" />
      <v-tabs-items v-if="model && model.key" v-model="tab.current">
        <v-tab-item
          v-for="tabItem in tab.tabs"
          :value="tabItem.key"
          :key="tabItem.key"
        >
          <!-- BOC:[custom tab] -->
          <component
            v-if="tabItem.component"
            :is="tabItem.component"
          ></component>
          <!-- EOC -->

          <BreadTabulatorDataView
            v-else-if="tabItem.key == 'info'"
            :api="api"
            :modelKey="model.key"
            :readTableHeaders="model.read.table.headers"
            operation="read"
          />
          <BreadTabulatorTabChildV2
            v-else-if="
              me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'child'
            "
            :tabProperties="tabItem"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.modelKey"
          />
          <BreadTabulatorTabIntermediate
            v-else-if="
              me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'intermediate'
            "
            :tabProperties="tabItem"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.modelKey"
            :intermediateChildModelKey="tabItem.intermediateChildModelKey"
            :isTeamAdmin="isTeamAdmin"
          />
          <BreadTabulatorTabChild
            v-else-if="me"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.key"
          />
          
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title'],
        'API',
        ['✓ Show loading', '✓ Show error', '✓ Show no items'],
        'Tabs',
        [
          '✗ Go to target tab if has tab query in url',
          '✗ Update url query upon changing tab',
          '✓ Enable customized tab',
        ],
      ]"
    />
    <!--EOC-->
  </div>
  <div v-else>
      <v-row>
        <v-col md="12">
          <v-alert
            type="error"
            dismissible
            border="left"
            elevation="2"
            icon="mdi-alert"
          >
            You don't have permission to browse this model.
          </v-alert>
        </v-col>
      </v-row>
    </div>  
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOC:[tab]
import Tab from "@/objects/tab";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
    auth: (state) => state.auth.data,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    ssoTeamApi: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbTabulator: new BreadcrumbTabulator(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    //BOC:[tab]
    tab: new Tab(),
    //EOC
    //BOC:[me]
    me: null,
    apiReadUrl: '',
    isTeamAdmin: false,
    childModelKey: '',
    recordCount: 0,
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[tab]
    var tabs = this.model.read.tabs
      ? this.$_.cloneDeep(this.model.read.tabs)
      : [];
    tabs.push({
      key: "info",
      label: "Info",
    });
    this.tab.setTabs(tabs);

    if(this.tab.tabs[0].key === 'ssoTeamToInternalUser'){
      this.childModelKey = 'ssoTeamToInternalUser';
      let ssoTeamId = this.$route.params.modelId;
      let internalUserUuid = this.auth.uuid;
      this.ssoTeamApi.setMethod('GET');
        this.ssoTeamApi.setUrl(`${this.$service['sso']}/v1/en/console/ssoTeamToInternalUser/${ssoTeamId}/${internalUserUuid}`);
        this.ssoTeamApi.setCallbackCompleted((resp) => {
          if(resp.data[0].isAdmin){
            this.isTeamAdmin = true;
          }else{
            this.isTeamAdmin = false;
          }
        });
        this.ssoTeamApi.fetch();
    }
    //EOC
    //BOC:[table]
    /* this.table.setTable(this.model.read.table);
    if (this.model.read === true) this.table.setTable(this.model.browse.table); */
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbTabulator.addForOperationRead({
      services: this.services,
      model: this.model,
      modelId: this.$route.params.modelId,
    });
    //EOC
    //BOC:[api]

    if(this.model.apiUrls.apiReadUrl != ''){
      this.apiReadUrl = `${this.$service[this.service.key]}${this.model.apiUrls.apiReadUrl}/${this.modelId}/read`;
    }else{
      this.apiReadUrl = `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/${this.modelId}/read`;
    }

    //console.log('apiReadUrl', this.apiReadUrl);

    this.api.setUrl(
      this.apiReadUrl
    );
    this.api.setCallbackCompleted((resp) => {
      //BreadDataView component is also used in browse operation. It only process array.
      this.api.setData([resp]);
      
      if (this.model.column && this.model.column.text) {
        this.modelName = resp[this.model.column.text];
      }
      else if (resp.name) {
        this.modelName = resp.name;
      }
      //BOC:[me]
      this.me = resp;
      this.recordCount = resp.length;
      //EOC
    });
    this.api.fetch();
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    goToBack(){
      // read url query
      /* const param = {
        paginationPageNo: this.$route.query.currentPageNo,
      }; 
      this.$store.commit("assignDeveloperData", param); */
      this.$router.go(-1);
    }
  },
};
</script>