export default class Table {
	constructor() {
		this.current = null;
		this.tabs = [];
	}
	set(prop,value) {
		this[prop] = value;
	}
	setTabs(tabs) {
		this.tabs = tabs;
		this.current = this.tabs[0];
	}
}